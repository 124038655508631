<template>
  <div>
    <div class="title">
      <div class="title_jl">在线简历</div>
      <div class="title_yl pointer" @click="previewClick">预览</div>
    </div>
    <div class="active">
      <div class="activeText" v-if="Person">
        <div v-if="Person.detail" class="activeTextr">
          <div class="activeName">
            <div class="nameleft">{{ Person.name }}</div>
            <img class="nameimg" :src="Person.avatar_url" v-if="Person.avatar_url" />
            <img class="nameimg" src="../../../assets/login/922028.png" v-else />
          </div>
          <div class="activeExperience">
            <div class="active_jingyan">
              <i class="el-icon-suitcase experience_icon"></i>
              <div class="experience">{{ Person.other_info.work_year }}年经验</div>
            </div>
            <div class="active_xt"></div>
            <div class="active_jingyan">
              <i class="el-icon-suitcase experience_icon"></i>
              <div class="experience">
                {{Person.detail ?  Person.detail.highest_education  : '无'}}
              </div>
            </div>
            <div class="active_xt" v-if="Person.other_info.job_status"></div>
            <div class="active_jingyan" v-if="Person.other_info.job_status">
              <i class="el-icon-suitcase experience_icon"></i>
              <div class="experience">{{ Person.other_info.job_status }}</div>
            </div>
          </div>
          <div class="activeExperience space-between" style="margin-top: 10px">
            <div class="row-center">
              <div class="active_jingyan">
                <i class="el-icon-suitcase experience_icon"></i>
                <div class="experience">{{ Person.other_info.age }}岁</div>
              </div>
              <div class="active_xt"></div>
              <div class="active_jingyan">
                <i class="el-icon-suitcase experience_icon"></i>
                <div class="experience">{{ Person.phone }}</div>
              </div>
            </div>
            <div class="row-center pointer" v-if="false">
              <img src="../../../assets/glxt/9.png" alt="" />
              <span class="text-blue font-normal">修改</span>
            </div>
          </div>
        </div>
         <div v-else class="addition activeTextr">
          <div class="addition_top">
            <div class="top_left">基本信息</div>
            <div class="top_right pointer" @click="navTo('/my-resume/basic')">
              <div class="">
                <img src="../../../assets/glxt/10.png" alt="" />
              </div>
              <div class="right_tianjia">添加</div>
            </div>
          </div>
          <div class="addition_up">展示您的基本信息，获得更多关注</div>
        </div>
        <!--  -->
         <div class="addition activeTextr">
          <div class="addition_top">
            <div class="top_left">个人优势</div>
            <div class="top_right pointer" @click="navTo('/my-resume/advantage',1)" v-if="!Person.detail || !Person.detail.advantage">
              <div class="">
                <img src="../../../assets/glxt/10.png" alt="" />
              </div>
              <div class="right_tianjia">添加</div>
            </div>
          </div>
          <div class="addition_up" v-if="Person.detail">
            {{
              Person.detail.advantage
                ? Person.detail.advantage
                : "展示您的个人优势，获得更多关注"
            }}
          </div>
        </div>
        <!-- 求职意向 -->
        <div class="addition activeTextr">
          <div class="addition_top" v-if="Person.job_intention.length == 0">
            <div class="top_left">求职意向</div>
            <div class="top_right pointer" @click="navTo('/my-resume/objective',1)">
              <div class="">
                <img src="../../../assets/glxt/10.png" alt="" />
              </div>
              <div class="right_tianjia">添加</div>
            </div>
          </div>
          <div class="addition_up"  v-if="Person.job_intention.length == 0">
            当前暂时无求职意向，添加后将给您推荐匹配度更高的职位
          </div>
           <div class="addition_top" v-if="Person.job_intention.length> 0">
            <div class="top_left">求职意向</div>
          </div>
         <div class="addition_ups" v-for="item in Person.job_intention" :key="item.id">
            {{ item.one_position }} ｜ {{ item.salary_min }}-{{
              item.salary_max
            }}
            ｜ {{ item.position_name }} ｜ {{ item.province }}
          </div>
        </div>
        <!-- 工作经历 -->
        <div class="addition activeTextr">
          <div class="addition_top">
            <div class="top_left">工作经历</div>
            <div
              class="top_right pointer"
              @click="navTo('/my-resume/experience',1)"
              v-if="Person.work.length == 0"
            >
              <div class="">
                <img src="../../../assets/glxt/10.png" alt="" />
              </div>
              <div class="right_tianjia">添加</div>
            </div>
          </div>
          <div class="addition_up" v-if="Person.work.length == 0">
            添加您的过往工作经历，展示您的工作能力与成就
          </div>
          <div class="addition_ups-one" v-else>
            <div v-for="item in Person.work" :key="item.id" class="addition_ups-one-item">
              <div class="row-center font-weight-bolder text-main addition_ups-one-heard">
                <div>{{ item.company_name }}</div>
                <div>{{ item.job_times }}</div>
              </div>
              <div class="font-weight-bolder text-main addition_ups-one-title">
                {{ item.work_name }}
              </div>
              <div class="row text-main font-normal">
                <div>内容：</div>
                <div class="workContent">
                  <div v-for="(workContent, index) in item.splitAdd" :key="index">
                    {{ workContent }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 教育经历 -->
        <div class="addition activeTextr">
          <div class="addition_top">
            <div class="top_left">教育经历</div>
            <div
              class="top_right pointer"
              @click="navTo('/my-resume/educational',1)"
              v-if="Person.school.length == 0"
            >
              <div class="">
                <img src="../../../assets/glxt/10.png" alt="" />
              </div>
              <div class="right_tianjia">添加</div>
            </div>
          </div>
          <div class="addition_up" v-if="Person.school.length == 0">
            添加您的教育经历，企业更加了解您
          </div>
          <div class="addition_up-one" v-else>
            <div class="row-center font-normal text-main">
              {{ Person.school[0].school_name }} ｜ {{ Person.school[0].school_times }}
            </div>
            <div class="font-normal text-main addition_up-one-title">
              {{ Person.school[0].major }} ｜ {{ Person.school[0].education }}
            </div>
            <div class="row addition_up-one-bottom font-normal text-main">
              <div>在校经历：</div>
              <div class="workContent-one">
                <div v-for="(workContent, index) in experience_one" :key="index">
                  {{ workContent }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 资格证书 -->
        <div class="addition activeTextr">
          <div class="addition_top">
            <div class="top_left">资格证书</div>
            <div
              class="top_right pointer"
              @click="navTo('/my-resume/certificate',1)"
              v-if="!Person.certificate"
            >
              <div class="">
                <img src="../../../assets/glxt/10.png" alt="" />
              </div>
              <div class="right_tianjia">添加</div>
            </div>
          </div>
           <div v-if="Person.certificate">
            <div
              class="addition_up"
              v-for="(item, index) in Person.certificate.certificates"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
          <div class="addition_up" v-if="Person.certificate && Person.certificate.certificates.length==0">暂未添加</div>
        </div>
      </div>
    </div>
    <mask-preview ref="maskPreview"></mask-preview>
  </div>
</template>

<script>
import apiTool from "../../../api/tool";
import api from "../../../api/user";
import maskPreview from "../components/preview.vue"
export default {
  components: {maskPreview},

  data() {
    return {
      Person: null, //简历相关的数据
      experience_one: [],
    };
  },

  created() {
    this.getResume();
  },
  watch: {},
  mounted() {},

  methods: {
    previewClick(){
      if(!this.Person.detail || this.Person.detail.completeness<90){
          this.$util.msg("你的简历完善度小于90%，请继续完善简历", "warning");
          return;
      }
      this.$refs.maskPreview.show()
    },
    navTo(path,id) {
      this.$router.push({ path ,query:{id}});
    },
    // 获取简历信息
    getResume() {
       const loading = this.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      api.getResume().then((res) => {
        if (res.code == 200) {
          if (res.data.work.length > 0) {
            res.data.work.forEach((item) => {
              if (item.to_hide == 1) {
                item.to_hide = true;
              } else {
                item.to_hide = false;
              }
              if (item.work_content) {
                item.splitAdd = item.work_content.split("\n");
              }
            });
          }
          if (res.data.school.length > 0) {
            if (res.data.school[0].experience) {
              this.experience_one = res.data.school[0].experience.split("\n");
            }
          }
          this.Person = res.data;
        }
        loading.close()
      });
    },
  },
};
</script>
<style lang="less" scoped>
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 51px;
  background: #ffffff;
  box-shadow: 0px 1px 0px 0px #f3f3f3;
  border-bottom: 2px solid #f3f3f3;
  .title_jl {
    font-size: 14px;
    font-weight: 400;
    color: #414a60;
    margin-left: 30px;
  }
  .title_yl {
    font-size: 14px;
    font-weight: 400;
    color: #126bf9;
    margin-right: 30px;
  }
}
.active {
  background: #ffffff;
  overflow: hidden;
  .activeText {
    margin: 17px 0px 30px;
    .activeName {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .nameleft {
        font-size: 24px;
        font-weight: 400;
        color: #24272e;
      }
      .nameimg {
        border-radius: 50%;
        width: 56px;
        height: 56px;
        overflow: hidden;
      }
    }
    .activeExperience {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .active_xt {
        width: 2px;
        height: 12px;
        background: #e0e0e0;
        margin: 0 14px 0 21px;
      }
      .active_jingyan {
        display: flex;
        align-items: center;
        .experience_icon {
          width: 14px;
          height: 14px;
        }
        .experience {
          margin-left: 10px;
          font-size: 14px;
          font-weight: 400;
          color: #414a60;
        }
      }
      img {
        width: 13px;
        height: 12px;
        margin-right: 6px;
      }
    }
    .redact {
      width: 100%;
      background: #f8f9fb;
      .redact_title {
        font-size: 16px;
        font-weight: 400;
        color: #414a60;
      }
      .redact_xinxi {
        display: flex;
        align-items: center;
        .xinxi_left {
          .xinxi_xingming {
            margin-top: 25px;
            font-size: 14px;
            font-weight: 400;
            color: #495060;
          }
          .xinxi_flex {
            width: 345px;
            margin-top: 11px;
            .flex_input {
              width: 231px;
              height: 38px !important;
              line-height: 38px !important;
            }
            .flex_tab {
              margin-left: 11px;
              font-size: 13px;
              font-weight: 400;
              color: #414a60;
            }
          }
        }
        .xinxi_right {
          margin-left: 180px;
          .xinxi_xingming {
            margin-top: 25px;
            font-size: 14px;
            font-weight: 400;
            color: #495060;
          }
          .xinxi_flex {
            width: 345px;
            margin-top: 11px;
          }
        }
      }
    }
    .addition {
      margin: 50px 0 0 0;
      .addition_top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .top_left {
          font-size: 18px;
          font-weight: 400;
          color: #414a60;
        }
        .top_right {
          display: flex;
          align-items: center;
          .right_blue {
            width: 13px;
            height: 13px;
            background-color: #126bf9;
          }
          .right_tianjia {
            font-size: 13px;
            font-weight: 400;
            color: #126bf9;
            margin-left: 10px;
          }
        }
        
      }
      .addition_up {
        margin-top: 30px;
        font-size: 14px;
        font-weight: 400;
        color: #aeb1bb;
      }
      .addition_up-one {
        margin-top: 28px;
        .addition_up-one-title {
          margin: 20px 0;
        }
        .addition_up-one-bottom {
          > div:first-child {
            font-size: 15px;
          }
          .workContent-one {
            line-height: 23px;
            font-size: 14px;
          }
        }
      }
    }
  }
}
.activeTextr {
  padding: 0 30px;
}
.active_bianji {
  padding: 28px 30px 25px;
}
</style>
<style lang="less">
.flex_input {
  width: 100%;
  height: 38px !important;
  line-height: 38px !important;
  .el-input__inner {
    width: 100% !important;
    height: 100% !important;
    line-height: 100% !important;
  }
}
.flex_inputs {
  width: 100% !important;
}
.xingming_nan {
  width: 147px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #e3e7ed;
  font-size: 14px;
  font-weight: 400;
  color: #9fa3b0;
  line-height: 38px;
  text-align: center;
}
.gender-index {
  background: #126bf9;
  color: #ffffff;
}
.flex_inputr {
  width: 315px;
}
.qzxz_right {
  .right_qx {
    width: 92px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #e3e7ed;
    font-size: 14px;
    font-weight: 400;
    color: #414a60;
    text-align: center;
    line-height: 36px;
  }
  .right_wc {
    width: 92px;
    height: 36px;
    background: #126bf9;
    border: 1px solid #e3e7ed;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    line-height: 36px;
    margin-left: 20px;
  }
}
.addition_ups-one {
  margin-top: 28px;
  .addition_ups-one-item {
    margin-bottom: 20px;
  }
  .addition_ups-one-item:last-child {
    margin-bottom: 0;
  }
  .addition_ups-one-heard {
    font-size: 15px;
    div:last-child {
      font-weight: 400;
      color: #9fa3b0;
      font-size: 13px;
      margin-left: 20px;
    }
  }
  .addition_ups-one-title {
    font-size: 15px;
    margin: 20px 0px;
  }
  .workContent {
    line-height: 23px;
  }
}
.addition_ups{
          margin-top: 10px;
        }
</style>
